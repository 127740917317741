import React from 'react';
import { css } from '@emotion/react';

import colors from '../../styles/_colors.module.scss';
import downloadImg from './media/download.png';
import downloadImg2x from './media/download2x.png';
import downloadImg3x from './media/download3x.png';
import Alert from '../../components/Alert';
import Grid from '../../components/layout/Grid';
import Link from '../../components/Link';
import Page, { Section } from '../../components/Page';
import routes from '../../routes';
import Image from '../../components/Image';
import AppStoreLink from '../../components/AppStoreLink';
import Text from '../../components/Text';
import Box from '../../components/layout/Box';
import theme from '../../theme';
import { Theme } from '@material-ui/core';

const appLinkCss = css`
  width: auto;
  height: 5.4rem;
  margin-right: 1.6rem;

  &:last-child {
    margin-right: 0;
  }

  ${theme.breakpoints.up('md')} {
    height: 6.3rem;
  }
`;

const Download = () => (
  <Page
    title="Download the App to Participate | Goosechase"
    description="Been invited to participate in a Goosechase? What a lucky duck! You’re only 2 simple steps away. To download the app, search ‘GooseChase’ on the App Store or Google Play."
  >
    <Section paddingTop="sm" paddingBot="md" css={{ textAlign: 'center' }}>
      <Box mt={{ xs: 0, md: '1.2rem' }} mb={{ xs: '1.6rem', md: '3.2rem' }}>
        <Text el="h1" variant="headingLg">
          Download the app to participate
        </Text>
      </Box>
      <Box margin="auto">
        <Text el="p" variant="paragraphMd">
          Been invited to participate in a Goosechase Experience? What a lucky duck!
        </Text>
        <Text el="p" variant="paragraphMd">
          You're only 2 simple steps away from an unforgettable adventure.
        </Text>
        <Box mt={'1.2rem'}>
          <Text el="p" variant="paragraphMd">
            Looking to create your own Goosechase Experience?{' '}
            <Link href={routes.HIW_ORGANIZING.path} variant="underline" useRouter>
              Go here
            </Link>{' '}
            to find out how
          </Text>
        </Box>
      </Box>
    </Section>

    <Section paddingTop="lg" paddingBot="none" css={{ backgroundColor: colors.accentLightYellow }}>
      <Box mt={{ xs: 0, md: '2rem' }}>
        <Grid container spacing={0} alignItems="flex-end">
          <Grid item xs={12} md={6}>
            <Box maxWidth="51.2rem" mr={{ xs: 0, md: '3.2rem' }}>
              <Text el="h2" variant="headingMd">
                2 steps to join
              </Text>
              <Text
                el="p"
                variant="paragraphMd"
                css={(theme: Theme) => ({
                  marginBottom: '2.8rem',
                  [theme.breakpoints.down('xs')]: { maxWidth: '33rem' },
                })}
              >
                1. Download the Goosechase app
                <br />
                2. Enter the unique join code or Experience name
              </Text>
              <Box mb={{ xs: '2.4rem', lg: '3.2rem' }} maxWidth={{ lg: '49.4rem' }}>
                <Text el="p" variant="paragraphMd">
                  Once you're in, it's go time! Complete Missions, get creative, earn points, compete for the top spot,
                  or just enjoy the submissions on the activity feed. The chase is as wild and wonderful as you make it.
                </Text>
              </Box>
              <Box mb={{ xs: '2.4rem', lg: '3.2rem' }} maxWidth={{ lg: '49.4rem' }}>
                <Text el="p" variant="paragraphMd">
                  To download the app, simply search ‘Goosechase’ on the App Store or Google Play, or use the buttons
                  below:
                </Text>
              </Box>
              <Box display="flex" alignItems="center" mb={{ xs: '2.4rem', lg: '8.8rem' }}>
                <AppStoreLink variant="apple" color="dark" css={appLinkCss} />
                <AppStoreLink variant="google" color="dark" css={appLinkCss} />
              </Box>
              <Box mb={{ xs: '2.4rem', md: '6.4rem' }} maxWidth={{ md: '46.2rem' }}>
                <Alert type="info" title="Pssst... looking to create a Goosechase Experience?" size="sm">
                  The Goosechase app is for participants to join Goosechase Experiences that have already been created.
                  <Link href={routes.HIW_ORGANIZING.path} variant="underline" useRouter>
                    Go here
                  </Link>{' '}
                  to learn how to create your own Goosechase Experience.{' '}
                </Alert>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Image
              src={downloadImg}
              srcSet={`${downloadImg2x} 2x, ${downloadImg3x} 3x`}
              alt="Download the Goosechase app"
              width="508"
              height="570"
              css={{
                display: 'block',
                margin: 'auto',
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Section>
  </Page>
);

export default Download;
